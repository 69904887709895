<template>
  <PageLayout>
    <div class="home">
      <!-- Hero header section -->
      <section class="home-hero-header">
        <div class="home-hero-image has-text-centered">
          <div class="home-hero-text">
            <h1 class="title has-text-white has-text-centered pb-3">{{$t('Frontend.Views.Home.Hero_Title')}}<sup>©</sup></h1>
            <h2 class="subtitle has-text-white">{{$t('Frontend.Views.Home.Hero_Subtitle')}}</h2>
          </div>
        </div>
      </section>
    </div>
  </PageLayout>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
const PageLayout = () => import(/* webpackChunkName: "home" */ '@/components/MainSiteElements/Frontend/PageLayout')

export default {
  name: 'home',

  components: {
    PageLayout
  },

  data() {
    return {
      screenWidth: 0,
      impressionPhotosSlider: 0,
      showImpressionImagesModal: false,
      showContactModal: false,
    }
  },

  created() {
    EventBus.$on('home-toggle-contact-modal', (val) => {
      this.showContactModal = val
    })

    this.setScreenWidth()
    window.addEventListener("resize", this.setScreenWidth)
  },
  destroyed() {
    window.removeEventListener("resize", this.setScreenWidth)
  },

  methods: {
    setScreenWidth() {
      this.screenWidth = screen.width
    },
    setShowContactModal() {
      this.showContactModal = true
    },
    setCloseContactModal() {
      this.showContactModal = false
    },
    setShowImpressionImagesModal() {
      if (this.screenWidth > 768) {
        this.showImpressionImagesModal = true
      }
    },
    setCloseImpressionImagesModal() {
      this.showImpressionImagesModal = false
    },
    goToNextSlide() {
      if (this.impressionPhotosSlider < 6) {
        this.impressionPhotosSlider++
      } else {
        this.impressionPhotosSlider = 0
      }

    },
    goToPreviousSlide() {
      if (this.impressionPhotosSlider > 0) {
        this.impressionPhotosSlider--
      } else {
        this.impressionPhotosSlider = 6
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  background-color: #fafafa;
}

/* Hero header styling for screenwidth > 768px */
@media only screen and (min-width: 768px) {
  .home-hero-header {
    height: 70vh;
  }
  .home-hero-image {
    background-image: url("../../assets/images/home/hero-header1.png");
    height: 70vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .home-hero-text {
    width: 75%;
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .home-hero-text .title {
    font-size: 2.5vw;
    text-shadow: 2px 2px #222;
  }
  .home-hero-text .subtitle {
    font-size: 1.5vw;
    text-shadow: 2px 2px #222;
  }
}

/* Hero header text styling for screenwidth < 768px */
@media only screen and (max-width: 768px) {
  .home-hero-header {
    height: 50vh;
  }
  .home-hero-image {
    background-image: url("../../assets/images/home/hero-header1.png");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .home-hero-text {
    width: 100%;
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .home-hero-text .title {
    font-size: 5vw;
    text-shadow: 2px 2px #222;
  }
  .home-hero-text .subtitle {
    font-size: 3vw;
    text-shadow: 2px 2px #222;
  }
}
</style>
